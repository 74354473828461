import React, { useState, useRef, useEffect, useMemo } from "react";
import Image from "next/image";
import Link from "next/link";
import {
  Box,
  Button,
  Flex,
  Text,
  Image as ImageComponent,
  DrawerContent,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerHeader,
  DrawerOverlay,
  useDisclosure,
  IconButton,
  Divider,
  VStack,
  Spinner,
} from "@chakra-ui/react";
import * as S from "../styles/LandingStyles";
import * as E from "../styles/LandingEmailFormStyles";

import { AuthModal } from "../components/Auth/withAuthModal";
import Head from "next/head";
import { useAuth } from "../utils/auth/newAuth";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import Carousel from "react-elastic-carousel";
import useWindowSize from "../components/Utility/windowSize";
import { homeFAQ } from "../data/faq";
import { FaqBanner } from "../components/Banner";
import { HeaderLine, ListingCardHolderGrid, SpinnerHolder } from "../styles/ListingsStyles";
import { BiCaretDown, BiCaretUp } from "react-icons/bi";
import { useQuery } from "react-query";
import * as Sentry from "@sentry/nextjs";
import BigSearcher from "../components/BigSearcher/BigSearcher";
import { SwipeableCards } from "../components/SwipeableCards/SwipeableCard";
import ListingCard from "../components/ListingCard/ListingCard2point0";
import { IoIosArrowForward } from "react-icons/io";
import prisma from "../lib/prisma";
import { AiFillContacts, AiFillEye, AiOutlineArrowRight } from "react-icons/ai";
import { GiOfficeChair } from "react-icons/gi";
import Home from "../components/home";
import { ImArrowRight2 } from "react-icons/im";
import { withUser } from "../utils/withUser";
import TopNavigation from "../components/Landing/TopNavigation";
import MobileNavigation from "../components/Landing/MobileNavigation";
import { clientAnalytics, clientError } from "../utils/utilties";
import { FaSearchLocation } from "react-icons/fa";
import { RiPriceTag3Fill } from "react-icons/ri";
import { DarkSection, WrapperFlexer, Wrapper, Benefits, DarkTitle, BenefitsFlex, Benefit, IconTitle, BenefitTitle, BenefitSpacer, BenefitsPara, BenefitsCTAHolder, PermanentCTAButton } from "../styles/LPStyles";
import { FcGoogle } from "react-icons/fc";
import { useRouter } from "next/router";
import path from "path";
interface LandingPageProps {

}
const Landing = (props: any) => {

  const [recentlyViewedListings, setRecentlyViewedListings] = useState([])
  const [newListings, setNewListings] = useState(props.newListings)
  const { userId, loading, profile } = useAuth();
  const [isAuthBoxOpen, setisAuthBoxOpen] = useState(false);
  const size = useWindowSize();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [menuValue, setMenuValue] = useState("");
  const [activeNewListingTab, setActiveNewListingTab] = useState("Retail")
  const [GoogleAuth, setGoogleAuth] = useState(false);
  const router = useRouter();
  // if (profile) {
  //   router.push("/listings");
  // }

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 814, itemsToShow: 2, itemsToScroll: 2 },
    { width: 1200, itemsToShow: 3 },
  ];

  const userData = [
    {
      image: "/images/landing/adeil-sheikh-amin.png",
      testimonial:
        "Great site and exposure. As a realtor, I am always looking for alternative ways to market my clients' listings, targeting a wider audience. As a buyer, there are many opportunities that are not listed hence a great way to gain access to them.",
      info: (
        <>
          Adel Sheikh Amin <br /> Sales Representative, Coldwell Banker Realty
          In Motion, Brokerage
        </>
      ),
    },
    {
      image: "/images/landing/marie-canonaco.svg",
      testimonial:
        "Very helpful platform providing excellent filters to help sort through inventory to help me find the best fit for my client.",
      info: (
        <>
          Marie Canonaco <br /> Salesperson, Remax Realty West Inc
        </>
      ),
    },
    // {
    //   image: "/images/landing/chamkaur-singh-sandhu.jpg",
    //   testimonial:
    //     "It's very well designed, with flexible filters, and easy to use. I am very satisfied with it. It is already become a go to website for my real estate needs in Canada. I recommend you go on Zonado regularly.",
    //   info: (
    //     <>
    //       Chamkaur Singh Sandhu <br /> Salesperson, International Realty Firm
    //       Inc. Brokerage
    //     </>
    //   ),
    // },
  ];

  const userCarouselRef = useRef(null);
  const popularSearchData = [
    {
      link: "https://zonado.com/listings/ON/Toronto",
      image: "/images/landing/toronto-commercial.jpg",
      title: "Toronto Businesses and Commercial Real Estate ",
    },
    {
      link: "https://zonado.com/listings/BC/Land",
      image: "/images/landing/bc-land.jpg",
      title: "Land for sale in BC",
    },
    {
      link: "https://zonado.com/listings/BC",
      image: "/images/landing/bc-commercial.jpg",
      title: "BC Commmercial Real Estate",
    },
    {
      link: "https://zonado.com/listings/AB",
      image: "/images/landing/alberta-commercial.jpg",
      title: "Alberta Businesses and Commercial Real Estate  ",
    },
    {
      link: "https://zonado.com/listings/AB/Edmonton",
      image: "/images/landing/edmonton-commercial.jpg",
      title: "Edmonton Businesses and Commercial Real Estate  ",
    },
    {
      link: "https://zonado.com/listings/ON/Mississauga",
      image: "/images/landing/mississauga-commercial.jpg",
      title: "Mississauga Businesses and Commercial Real Estate ",
    },
    {
      link: "https://zonado.com/listings/AB/Calgary",
      image: "/images/landing/calgary-commercial.jpg",
      title: "Calgary Businesses and Commercial Real Estate ",
    },
    {
      link: "https://zonado.com/listings/AB/Land",
      image: "/images/landing/alberta-land.jpg",
      title: "Land for Sale in Alberta",
    },
    {
      link: "https://zonado.com/listings/AB/Retail",
      image: "/images/landing/alberta-retail.jpg",
      title: "Retail for Sale in Alberta",
    },
    {
      link: "https://zonado.com/listings/AB/Sale-of-Business",
      image: "/images/landing/alberta-business.png",
      title: "Businesses for Sale in Alberta",
    },
    {
      link: "https://zonado.com/listings/AB/Industrial",
      image: "/images/landing/alberta-industrial.jpg",
      title: "Industrial Listings in Alberta",
    },
    {
      link: "https://zonado.com/listings/SK",
      image: "/images/landing/saskatchewan-commercial.jpg",
      title: "Commercial Listings in Saskatchewan ",
    },
    {
      link: "https://zonado.com/listings/ON/Ottawa",
      image: "/images/landing/ottawa-commercial.jpg",
      title: "Ottawa Businesses and Commercial Real Estate ",
    },
  ];

  const popCarouselRef = useRef(null);
  const popCarouselTotalPages = 11;
  let popCarouselResetTimeout;
  const onNextPopStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      popCarouselRef.current.goTo(0);
    }
  };
  const onPrevPopStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      popCarouselRef.current.goTo(popularSearchData.length);
    }
  };

  const [listingCategories, setListingCategories] = useState({
    listingPropertyTypes: [],
    listingBusinessCategories: [],
    businessesByCity: [],
    businessesByProvince: [],
    businessesByType: [],
    propertiesByCity: [],
    propertiesByProvince: [],
  });

  async function getListingCategories() {
    try {
      const result = await fetch("/api/listingCategories");
      const response = result.json();
      return response;
    } catch (error) {
      console.log(error);
    }
  }

  useQuery("listingsCategories", getListingCategories, {
    onSuccess: (data) => {
      setListingCategories(data);
    },
    onError: (error) => {
      clientError(error);
    },
  });

  const handleTabClick = (e) => {
    const { id } = e.target
    setActiveNewListingTab(id)
  }

  async function getIPAddress() {
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      return data.ip
    } catch (error) {
      console.log("Unable to fetch IP address", error)
    }
  }

  async function getCoords(ipAddress) {

    try {
      const response = await fetch(`https://api.iploka.com/${ipAddress}?api_key=7b697ad73dc322a4a4a62247dc012b23150f1d78`)
      const data = await response.json()
      const { latitude, longitude } = data;
      return { latitude, longitude }

    }
    catch (e) {
      console.error(e)
    }
  }

  const nearbyListingsExist = useMemo(() => Object.keys(newListings).includes('close_to_me'), [newListings])

  // Fetches nearby and recently viewed listings
  async function getNearbyListings() {

    try {
      const ipAddress = await getIPAddress()
      const queries = {
        coordinates: await getCoords(ipAddress),
        ipAddress
      }
      const responseObj = await fetch('/api/listings/landing-page', {
        headers: {
          queries: JSON.stringify(queries)
        }
      });
      const { recentlyViewed, nearbyListings } = await responseObj.json();

      if (!!recentlyViewed && nearbyListings.length) {
        setNewListings({
          ...newListings,
          "close_to_me": nearbyListings
        });
        setActiveNewListingTab('close_to_me');

      }
      if (!!recentlyViewed && recentlyViewed.length) {
        setRecentlyViewedListings(recentlyViewed)
      }

    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getNearbyListings()
  }, [])

  const whatsOnZonadoSectionData = [
    {
      propertyType: 'Land',
      imgLink: '/images/lp/Land/hero.jpg',
      pageLink: "/listings/Land"
    },
    {
      propertyType: 'Retail',
      imgLink: '/images/newLanding/listingOfferings/retail.jpg',
      pageLink: "/listings/Retail"
    },
    {
      propertyType: 'Multifamily',
      imgLink: '/images/lp/Multifamily/hero.jpg',
      pageLink: "/listings/Multifamily"
    },
    {
      propertyType: 'Business',
      imgLink: '/images/newLanding/listingOfferings/business.jpg',
      pageLink: "/listings/Sale-of-Business"
    },
    {
      propertyType: 'Industrial',
      imgLink: '/images/newLanding/listingOfferings/industrial.jpg',
      pageLink: "/listings/Industrial"
    },
    {
      propertyType: 'Office',
      imgLink: '/images/newLanding/listingOfferings/office.jpg',
      pageLink: "/listings/Office"
    },
  ]

  const Testimonial = ({
    testimonialText,
    authorName,
    authorPosition,
    authorRole,
    companyLogo
  }) => {
    return (
      <S.TestimonialContainer>
        <S.TestimonialText>"{testimonialText}"</S.TestimonialText>
        {/* <Flex>
          <Box>
            <Image
              src="images/newLanding/trustedCompanies/Ellipse 67.svg"
            />
          </Box>
          <Box>
            <Text><strong>Jammilah John-Keating</strong></Text>
            <Text>CEO, PropertyNerds</Text>
          </Box>
        </Flex> */}
        <S.TestimonialAuthor>
          <S.CompanyLogo>
            <Image
              src={companyLogo}
              width="40px"
              height="40px"
            />
          </S.CompanyLogo>
          <Flex
            flexDir={'column'}
            justifyContent="space-between"
            alignItems={'flex-start'}
            h="40px"
          >
            <S.TestimonialName><strong>{authorName}</strong>{(size.width <= 900 && authorPosition) && `, ${authorPosition}`}</S.TestimonialName>
            <S.TestimonialRole>{(size.width > 900 && authorPosition) && `${authorPosition}, `}{authorRole}</S.TestimonialRole>
          </Flex>
        </S.TestimonialAuthor>
      </S.TestimonialContainer>
    )
  }

  if (loading) {
    return (
      <SpinnerHolder>
        <Spinner size="xl" color="green.800" />
      </SpinnerHolder>
    )
  }
  if (!loading && !!profile) {
    return <Home />
  }


  return (
    <>
      <Head>
        <title key="title">
          Zonado - Business and Commercial Real Estate Listings
        </title>
        <meta
          key="description"
          name="description"
          content="Private and public listings to sell, lease, and buy Canadian business and commercial real estate. List and reach buyers directly."
        />
        <meta
          key="og:title"
          property="og:title"
          content="Zonado - Canadian Business and Commercial Real Estate Listings"
        />
        <meta
          key="og:description"
          property="og:description"
          content="Private and public listings to sell, lease, buy Canadian businesses and commercial real estate. List and reach buyers directly."
        />
        <link rel="canonical" href="https://zonado.com" />
      </Head>
      <AuthModal
        onClose={() => setisAuthBoxOpen(false)}
        isOpen={isAuthBoxOpen}
      />

      <S.TopBanner>
        <S.TopBannerWrapper>
          <S.BannerRow>
            <S.ColContent>
              <S.FBLabel>
                Get Closing </S.FBLabel>
              <S.Slogan>Find Canadian Businesses and Commercial Real Estate for Sale</S.Slogan>
              <S.ImageBlock><S.ImageEl src={'/images/landing/showcasebig 2.png'} /></S.ImageBlock>
              <S.BelowImageText>Discover Canada's fastest growing marketplace for both MLS® and off-market (exclusive, direct sale) listings with a suite of powerful tools to help you close deals faster.</S.BelowImageText>
              <S.ListHolder><S.IconsList>
                <S.IconItem>Browse thousands of listings you won't find anywhere else</S.IconItem>
                <S.IconItem>Post listings for free, get free leads</S.IconItem>
                <S.IconItem>Connect directly with agents, investors, and tenants </S.IconItem>
                <S.IconItem>Maximize your reach without breaking the bank</S.IconItem>
              </S.IconsList>
              </S.ListHolder>

              <Link passHref href={"/signup"}>
                <a>
                  <Button
                    fontWeight={400}
                    variant="solid"
                    colorScheme="green"
                    w="225px"
                    height="55px"
                  >
                    Create a free account
                  </Button>
                </a>
              </Link>


            </S.ColContent>
            <S.ColMedia>
              <S.ImageBlockDesktop><S.ImageEl src={'/images/landing/showcasebig 2.png'} alt="" /></S.ImageBlockDesktop> </S.ColMedia>
          </S.BannerRow>
        </S.TopBannerWrapper>
      </S.TopBanner>

      <S.StatsSection id="whyus">
        <WrapperFlexer>
          <S.BenefitsWrapper>
            <BenefitsFlex>
            <Benefit>
                <S.StatTitle>
                  <Box
                    mr="1rem"
                    color="#1a202c"
                    as={RiPriceTag3Fill}
                    w={{ base: "28px", md: "35px" }}
                    h={{ base: "28px", md: "35px" }}
                  />
                  $0 Fee
                </S.StatTitle>

                <S.StatDesc>For searching, listing, and leads</S.StatDesc>
              </Benefit>

              <Benefit>
                <S.StatTitle>
                  <Box
                    mr="1rem"
                    color="#1a202c"
                    as={AiFillContacts}
                    w={{ base: "28px", md: "35px" }}
                    h={{ base: "28px", md: "35px" }}
                  />
                  3,000+
                </S.StatTitle>

                <S.StatDesc>Qualified leads delivered</S.StatDesc>
              </Benefit>

            
              <Benefit>
                <S.StatTitle>
                  <Box
                    mr="1rem"
                    color="#1a202c"
                    as={FaSearchLocation}
                    w={{ base: "28px", md: "35px" }}
                    h={{ base: "28px", md: "35px" }}
                  />
                  10,000+
                </S.StatTitle>

                <S.StatDesc>Monthly Canadian users</S.StatDesc>
              </Benefit>

              <Benefit>
                <S.StatTitle>
                  <Box
                    mr="1rem"
                    color="#1a202c"
                    as={AiFillEye}
                    w={{ base: "28px", md: "35px" }}
                    h={{ base: "28px", md: "35px" }}
                  />
                  200,000+
                </S.StatTitle>

                <S.StatDesc>Views of Zonado listings</S.StatDesc>
              </Benefit>


             
            </BenefitsFlex>
          </S.BenefitsWrapper>
        </WrapperFlexer>
      </S.StatsSection>

      <E.SectionSignup className="wf-section">
        <S.TopBannerWrapper>
          <E.Card >
            <E.CardContent >
              <E.SignupFormWrap >
                <E.HSignupForm >
                  <E.Form
                    aria-label="Signup Form"
                    onSubmit={(e) => {
                      e.preventDefault()
                      router.push({
                        pathname: '/signup',
                        query: { email: e.target.email.value },
                      })
                    }}
                  >
                    <E.Field
                      type="email"
                      maxLength="256"
                      name="email"
                      data-name="email"
                      placeholder="Your email"
                      id="email-4"
                      required
                    />
                    <Button
                      fontWeight={400}
                      variant="solid"
                      colorScheme="green"
                      borderRadius="4px"
                      padding="8px 16px"
                      fontSize="16px"
                      lineHeight="24px"
                      height="auto"
                      type="submit"
                      width={{ base: "100%", md: "auto" }}
                    >
                      Sign up
                    </Button>
                    {/* <E.SubmitButton
                type="submit"
                value="Sign up"
                data-wait="Please wait..."
              >Sign up</E.SubmitButton> */}
                  </E.Form>
                </E.HSignupForm>
                <E.OrText>or</E.OrText>
                <Link
                  passHref
                  href={{
                    pathname: '/signup',
                    query: { auth: 'google' }
                  }}>
                  <a>

                    <Button
                      leftIcon={<FcGoogle />}
                      color="#000"
                      backgroundColor="#fff"
                      border="1px solid #000"
                      borderRadius="4px"
                      padding="8px 16px"
                      fontSize="16px"
                      lineHeight="24px"
                      textDecoration="none"
                      fontWeight={400}
                      transition="all .15s"
                      height="48px"
                      paddingTop=".7em"
                      paddingBottom=".7em"
                      borderColor="#d4d4d4"
                      width={{ base: "100%", md: "auto" }}

                    >
                      Sign up with Google
                    </Button>
                  </a>

                </Link>
              </E.SignupFormWrap>

            </E.CardContent>
          </E.Card>
          <E.FlexHSplit >
            {/* <E.TextSecondary className="text-secondary mb-16-sm mw-500">
            Looking to fund raise or invest in the next generation of great startups? AngelList Venture can now be found at{' '}
            <a target="_blank" href="http://angellist.com/" className="f-secondary">
              AngelList.com
            </a>
            .
          </E.TextSecondary> */}
            <E.TextSecondary onClick={() => { router.push('/signup') }}>
              Already have an account?{' '}
              <E.LinkSecondary href="/login" target="_blank" className="link-secondary">
                Log in
              </E.LinkSecondary>
              .
            </E.TextSecondary>
          </E.FlexHSplit>
        </S.TopBannerWrapper>
      </E.SectionSignup>
      
      <S.WhatsOnZonado>
        <S.SectionTitle style={{ marginBottom: "15px" }}>What's on Zonado?</S.SectionTitle>
        <S.PropertyTypeCardWrapper>
          {
            whatsOnZonadoSectionData.map((section, index) => (

              <Link href={section.pageLink} passHref key={index}>
                <S.PropertyTypeCard
                  img={section.imgLink}
                >
                  <Box pl="12px" pb="12px">
                    <S.PropertyTypeCardText>
                      {section.propertyType}
                    </S.PropertyTypeCardText>
                    <S.PropertyTypeCardLink>
                      <span>View all</span> <span><AiOutlineArrowRight /></span>
                    </S.PropertyTypeCardLink>
                  </Box>
                </S.PropertyTypeCard>
              </Link>
            ))
          }
        </S.PropertyTypeCardWrapper>
      </S.WhatsOnZonado>


      <Box m="80px auto 150px">
        <Testimonial
          testimonialText={`I absolutely love this platform. 10/10. I'm sharing it with all my contacts.`
          }
          authorName="Anthony Spano"
          authorPosition="Salesperson"
          authorRole="Royal LePage Your Community Realty."
          companyLogo={"/images/newLanding/testimonials/Anthony Spano.svg"}
        />
      </Box>


      <S.NewListingsContainer>
        <S.SectionTitle style={{ marginBottom: "15px" }}>New Listings</S.SectionTitle>
        <S.NewListingTabs>
          {
            nearbyListingsExist &&
            <S.NewListingTab onClick={handleTabClick} id="close_to_me" active={activeNewListingTab === 'close_to_me'}>Close to me</S.NewListingTab>
          }
          <S.NewListingTab onClick={handleTabClick} id="Retail" active={activeNewListingTab === 'Retail'}>Retail</S.NewListingTab>
          <S.NewListingTab onClick={handleTabClick} id="Multifamily" active={activeNewListingTab === 'Multifamily'}>Multifamily</S.NewListingTab>
          <S.NewListingTab onClick={handleTabClick} id="Businesses" active={activeNewListingTab === 'Businesses'}>Businesses</S.NewListingTab>
          <S.NewListingTab onClick={handleTabClick} id="Industrial" active={activeNewListingTab === 'Industrial'}>Industrial</S.NewListingTab>
          <S.NewListingTab onClick={handleTabClick} id="Office" active={activeNewListingTab === 'Office'}>Office</S.NewListingTab>
        </S.NewListingTabs>

        <S.NewListingsGridContainer>
          {

            newListings[activeNewListingTab].slice(0, size.width < 900 ? 4 : undefined).map((listing, index) => (
              <ListingCard
                {...listing}
                key={listing.id}
                noSeparator
              />
            ))
          }
        </S.NewListingsGridContainer>
      </S.NewListingsContainer>

      <Flex
        justifyContent={'center'}
        mt="32px"
      >
        <Link href={activeNewListingTab === 'close_to_me' ? '/listings' : `/listings/${activeNewListingTab == "Businesses" ? "Sale-of-Business" : activeNewListingTab}`} passHref>
          <a>
            <Button
              margin="0 auto"
              fontWeight={500}
              variant="outline"
              colorScheme="green"
              w="225px"
              height="55px"
            >
              View all
            </Button>
          </a>
        </Link>
      </Flex>

      <Box my="150px">
        <Testimonial
          testimonialText={`The deal I just signed was a buyer from 
  Zonado - the first person I spoke to. I have a team of 6 and have made this a mandatory tool for them.`
          }
          authorName="Jammilah Dixon-Keating"
          authorRole="PropertyNerds"
          authorPosition={'CEO'}
          companyLogo={"/images/newLanding/testimonials/Jammilah Dixon-Keating.svg"}
        />
      </Box>
      <S.SummaryBannerContainer>
        <S.SectionHeader>How it works</S.SectionHeader>
        <S.SummaryCardContainer>
          <Box px={size.width < 700 && "20px"}>
            <ImageComponent
              width={75}
              height={73}
              src="/images/landing/search-and-list.svg"
              alt="Searching through a paper"
              margin={"0 auto"}
            />
            <S.SummaryTextContainer>
              <p>Search, free</p>
              <p>
                Searching properties on Zonado and sending inquiries
                is completely free
              </p>
            </S.SummaryTextContainer>
          </Box>
          <Box px={size.width < 700 && "20px"}>
            <ImageComponent
              width={75}
              height={75}
              src="/images/landing/message-directly.svg"
              alt="Speech bubbles"
              margin={"0 auto"}
            />
            <S.SummaryTextContainer>
              <p>Message directly</p>
              <p>
                Connect directly with listing owners and agents and close deals
                faster
              </p>
            </S.SummaryTextContainer>
          </Box>
          <Box px={size.width < 700 && "20px"}>
            <ImageComponent
              width={75}
              height={75}
              src="/images/landing/create-and-save-alert.svg"
              alt="Bell icon"
              margin={"0 auto"}
            />
            <S.SummaryTextContainer>
              <p>Create and save alerts</p>
              <p>
                Be the first to know as soon as a listing matching your criteria
                is listed
              </p>
            </S.SummaryTextContainer>
          </Box>
          <Box px={size.width < 700 && "20px"}>
            <ImageComponent
              width={75}
              height={75}
              src="/images/landing/post-a-want.svg"
              alt="Searching through a clipboard"
              margin={"0 auto"}
            />
            <S.SummaryTextContainer>
              <p>Post a Want</p>
              <p>
                Post exactly what you're in the market for and get listings from
                those who have them
              </p>
            </S.SummaryTextContainer>
          </Box>
        </S.SummaryCardContainer>
        <S.SummaryButtonContainer>
          <Link passHref href={"/listings?source=section-link"}>
            <a>
              <Button
                fontWeight={500}
                w="151px"
                h="48px"
                variant={"outline"}
                borderColor={"#223A5A"}
                color="#223A5A"
                _hover={{ bg: "#ECE7D4" }}
              >
                Search listings
              </Button>
            </a>
          </Link>
          <Link passHref href={"/add-listing?source=section-link"}>
            <a>
              <Button
                fontWeight={500}
                w="151px"
                h="48px"
                variant={"outline"}
                borderColor={"#223A5A"}
                color="#223A5A"
                _hover={{ bg: "#ECE7D4" }}
              >
                Add a Free Listing
              </Button>
            </a>
          </Link>
          <Link passHref href={"/active-wants?source=section-link"}>
            <a>
              <Button
                fontWeight={500}
                w="151px"
                h="48px"
                variant={"outline"}
                borderColor={"#223A5A"}
                color="#223A5A"
                _hover={{ bg: "#ECE7D4" }}
              >
                Add a Want
              </Button>
            </a>
          </Link>
        </S.SummaryButtonContainer>
      </S.SummaryBannerContainer>
      <S.PhoneCardVectors>
        <Box maxW={"1400px"} m="0 auto" px="20px">
          <S.PhoneCard>
            <Flex flexDir={"column"}>
              <S.PhoneCardHeader>Add a free listing</S.PhoneCardHeader>
              <S.PhoneCardSubtitle>
                Zonado is used by thousands of investors, business owners, and
                agents to find spaces for their business, development opportunities, or
                their next investment.
              </S.PhoneCardSubtitle>
              <Flex flexDir={"column"}>
                <Flex flexDir={size.width > 580 ? "row" : "column"}>
                  <ImageComponent
                    src="/images/landing/blue-list.svg"
                    w="48px"
                    h="48px"
                  />
                  <Box p={size.width > 580 ? "0 0 25px 22px" : "5px 0 35px 0"}>
                    <S.PhoneCardRowHeader>Add unlimited listings</S.PhoneCardRowHeader>
                    <S.PhoneRowText>
                      There's no limit on the number of listings you can add.
                      Upload on your own or send us an email at{" "}
                      <a href="mailto:hello@zonado.com">hello@zonado.com</a> to
                      get some help.
                    </S.PhoneRowText>
                  </Box>
                </Flex>
                <Flex flexDir={size.width > 580 ? "row" : "column"}>
                  <ImageComponent
                    src="/images/landing/green-check.svg"
                    w="48px"
                    h="48px"
                  />
                  <Box p={size.width > 580 ? "0 0 25px 22px" : "5px 0 25px 0"}>
                    <S.PhoneCardRowHeader>
                      Quality, verified leads
                    </S.PhoneCardRowHeader>
                    <S.PhoneRowText>
                      Every user on Zonado must validate their email and verify
                      their phone number prior to creating an account - so you
                      can spend your time dealing with serious leads only.
                    </S.PhoneRowText>
                  </Box>
                </Flex>
                <Flex flexDir={size.width > 580 ? "row" : "column"}>
                  <ImageComponent
                    src="/images/landing/pink-radar.svg"
                    w="48px"
                    h="48px"
                  />
                  <Box p={size.width > 580 ? "0 0 25px 22px" : "5px 0 25px 0"}>
                    <S.PhoneCardRowHeader>
                      Smart alerts and detailed analytics
                    </S.PhoneCardRowHeader>
                    <S.PhoneRowText>
                      Zonado goes far beyond leads. Get detailed analytics on
                      who is taking actions on your listings and get alerts each
                      time someone opens your flyers, clicks on your phone
                      number, etc. so you never miss an opportunity.
                    </S.PhoneRowText>
                  </Box>
                </Flex>
                <Link passHref href={"/selling"}>
                  <a
                    style={{
                      margin: "0 auto",
                      maxWidth: "256px",
                      width: "100%",
                    }}
                  >
                    <Button
                      fontWeight={500}
                      mt="30px"
                      w="100%"
                      h="55px"
                      variant={"solid"}
                      colorScheme='green'
                      _hover={{
                        bg: "#74C493",
                      }}
                    >
                      Learn more
                    </Button>
                  </a>
                </Link>
              </Flex>
            </Flex>
            {size.width >= 960 && (
              <Flex justifyContent={"center"} alignItems="center">
                <Image
                  src="/images/landing/zonado-phone.svg"
                  width="415px"
                  height="545px"
                  layout="intrinsic"
                />
              </Flex>
            )}
          </S.PhoneCard>
        </Box>
      </S.PhoneCardVectors>

      <Box my={"150px"}>
        <Testimonial
          testimonialText={`Great site and exposure. As a realtor, I am always looking for alternative ways to market my clients' listings, targeting a wider audience. As a buyer, there are many opportunities that are not listed hence a great way to gain access to them.`
          }
          authorName="Adel Sheikh Amin"
          authorPosition={""}
          authorRole="Coldwell Banker Realty in Motion, Brokerage"
          companyLogo={"/images/newLanding/testimonials/Adel Sheikh Amin.svg"}
        />
      </Box>

      <Flex m={"0 auto"} mb="170px" flexDir={"column"} maxW="1440px" px="20px">
        <S.SectionHeader>Popular Searches</S.SectionHeader>
        {size.width >= 814 ? (
          <Box>
            <Carousel
              isRTL={false}
              pagination={false}
              enableAutoPlay
              autoPlaySpeed={8000}
              tiltEasing="cubic-bezier(0.110, 1, 1.000, 0.210)"
              transitionMs={2000}
              breakPoints={breakPoints}
              ref={popCarouselRef}
              onPrevStart={onPrevPopStart}
              onNextStart={onNextPopStart}
              onNextEnd={({ index }) => {
                clearTimeout(popCarouselResetTimeout);
                if (index + 1 === popCarouselTotalPages) {
                  if (popCarouselRef?.current?.goTo) {
                    popCarouselResetTimeout = setTimeout(() => {
                      if (popCarouselRef?.current?.goTo) {
                        popCarouselRef.current.goTo(0);
                      }
                    }, 8000);
                  }
                }
              }}
              renderArrow={({ type, onClick }) => {
                return (
                  <Flex alignItems={"center"}>
                    <IconButton
                      fontSize={"30px"}
                      boxSize={"50px"}
                      isRound
                      fontWeight="500"
                      variant="solid"
                      colorScheme={'green'}
                      aria-label="Search database"
                      icon={
                        type === "PREV" ? (
                          <ChevronLeftIcon />
                        ) : (
                          <ChevronRightIcon />
                        )
                      }
                      onClick={onClick}
                    />
                  </Flex>
                );
              }}
            >
              {popularSearchData.map((item, index) => {
                return (
                  <a href={item.link}>
                    <S.PopularSearchCard key={index}>
                      <Image
                        src={item.image}
                        width="383px"
                        height="257px"
                        layout="intrinsic"
                      />
                      <Text
                        fontSize="18px"
                        fontWeight={500}
                        lineHeight="22.68px"
                        color="#4a4a4a"
                        mb="6px"
                        p="13px 16px"
                      >
                        {item.title}
                      </Text>
                    </S.PopularSearchCard>
                  </a>
                );
              })}
            </Carousel>
          </Box>
        ) : (
          <S.ScrollContainer>
            {popularSearchData.map((item, index) => {
              return (
                <a href={item.link} key={index}>
                  <S.PopularSearchCard>
                    <Image
                      src={item.image}
                      width="323px"
                      height="197px"
                      layout="intrinsic"
                    />
                    <Text
                      fontWeight={700}
                      lineHeight="22.68px"
                      color="#125F3F"
                      mb="6px"
                      p="13px 16px"
                    >
                      {item.title}
                    </Text>
                  </S.PopularSearchCard>
                </a>
              );
            })}
          </S.ScrollContainer>
        )}
      </Flex>
      <S.CompaniesContainer>
        <S.SectionHeader>Trusted by the Best</S.SectionHeader>
        <S.CompanyImageContainer>
          <Box>
            <Image
              src={"/images/landing/avison-young.svg"}
              width="143.41px"
              height="88px"
              layout="intrinsic"
            />
          </Box>
          <Box>
            <Image
              src={"/images/landing/colliers.svg"}
              width="156.44px"
              height="88px"
              layout="intrinsic"
            />
          </Box>
          <Box>
            <Image
              src={"/images/landing/cushman-wakefield.svg"}
              width="242px"
              height="52px"
              layout="intrinsic"
            />
          </Box>
          <Box>
            <Image
              src={"/images/landing/jll.svg"}
              width="145px"
              height="64px"
              layout="intrinsic"
            />
          </Box>
          <Box>
            <Image
              src={"/images/landing/remax.svg"}
              width="184px"
              height="50px"
              layout="intrinsic"
            />
          </Box>
          <Box>
            <Image
              src={"/images/landing/cbre.svg"}
              width="164.68px"
              height="42.01px"
              layout="intrinsic"
            />
          </Box>
        </S.CompanyImageContainer>
      </S.CompaniesContainer>
      <S.AboutBannerContainer>
        <S.AboutTextContainer>
          <S.AboutText>
            Using Zonado means getting access to rare opportunities that you
            won't find elsewhere, while being sure of an authentic, professional
            experience.
          </S.AboutText>
          <S.AboutText bold>
            Whatever you're looking for, we're here to help you find it.
          </S.AboutText>
        </S.AboutTextContainer>
        <Link passHref href="/about-us?section=section-link">
          <a style={{ margin: "0 auto" }}>
            <Button
              fontWeight={500}
              w="190px"
              h="48px"
              variant={"outline"}
              colorScheme='green'
            >
              Meet the Founders
            </Button>
          </a>
        </Link>
      </S.AboutBannerContainer>

      <Drawer
        onClose={onClose}
        isOpen={isOpen}
        size={"full"}
        placement="bottom"
      >
        <DrawerOverlay />
        <DrawerContent height="92vh">
          <DrawerCloseButton />
          <DrawerHeader
            fontFamily={"'Jarkarta', sans-serif"}
            fontSize={"24px"}
            lineHeight="1.33"
            pr="48px"
          >
            Pick a property type to get started
          </DrawerHeader>
          <DrawerBody>
            <Text
              color="#626262"
              fontSize={"11px"}
              letterSpacing="1.65px"
              lineHeight="1.45"
              cursor={"default"}
              fontWeight={700}
              fontFamily={"'Jarkarta', sans-serif"}
            >
              PROPERTY TYPES
            </Text>
            <Text
              py="16px"
              borderBottom={"1px solid rgba(0, 0, 0, 0.15)"}
              fontFamily={"'Jarkarta', sans-serif"}
              _hover={{ cursor: "pointer" }}
              onClick={() => {
                setMenuValue("Land");
                onClose();
              }}
            >
              Land
            </Text>
            <Text
              py="16px"
              borderBottom={"1px solid rgba(0, 0, 0, 0.15)"}
              fontFamily={"'Jarkarta', sans-serif"}
              _hover={{ cursor: "pointer" }}
              onClick={() => {
                setMenuValue("Multifamily");
                onClose();
              }}
            >
              Multifamily
            </Text>
            <Text
              py="16px"
              borderBottom={"1px solid rgba(0, 0, 0, 0.15)"}
              fontFamily={"'Jarkarta', sans-serif"}
              _hover={{ cursor: "pointer" }}
              onClick={() => {
                setMenuValue("Retail");
                onClose();
              }}
            >
              Retail
            </Text>
            <Text
              py="16px"
              borderBottom={"1px solid rgba(0, 0, 0, 0.15)"}
              fontFamily={"'Jarkarta', sans-serif"}
              _hover={{ cursor: "pointer" }}
              onClick={() => {
                setMenuValue("Businesses");
                onClose();
              }}
            >
              Sale of Businesses
            </Text>
            <Text
              py="16px"
              borderBottom={"1px solid rgba(0, 0, 0, 0.15)"}
              fontFamily={"'Jarkarta', sans-serif"}
              _hover={{ cursor: "pointer" }}
              onClick={() => {
                setMenuValue("Industrial");
                onClose();
              }}
            >
              Industrial
            </Text>
            <Text
              py="16px"
              borderBottom={"1px solid rgba(0, 0, 0, 0.15)"}
              fontFamily={"'Jarkarta', sans-serif"}
              _hover={{ cursor: "pointer" }}
              onClick={() => {
                setMenuValue("Office");
                onClose();
              }}
            >
              Office
            </Text>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <FaqBanner data={homeFAQ} />
      {listingCategories &&

      <Box maxW={"1400px"} mx="auto" p="20px">
        <Flex
          justifyContent={"space-between"}
          columnGap="40px"
          flexDir={size.width > 720 ? "row" : "column"}
        >
          <Box>
            <HeaderLine>Property types</HeaderLine>
            <S.TypesWithListingsSection>
              {listingCategories?.listingPropertyTypes.map(
                (listingPropertyType, index) => (
                  <Link
                    key={index}
                    href={{
                      pathname: listingPropertyType.link,
                    }}
                    passHref
                  >
                    <a>
                      <Button
                        _focus={{
                          boxShadow: "none",
                        }}
                        variant={"link"}
                        color="green.500"
                        fontWeight={"normal"}
                        id="listingTypeBtn"
                      >
                        {listingPropertyType.value}
                      </Button>
                    </a>
                  </Link>
                )
              )}
            </S.TypesWithListingsSection>
          </Box>
          
          <Box>
            <HeaderLine>Business Types</HeaderLine>
            <S.TypesWithListingsSection>
              {listingCategories.listingBusinessCategories.map(
                (listingBusinessCategory, index) => (
                  <Link
                    key={index}
                    href={{
                      pathname: listingBusinessCategory.link,
                    }}
                    passHref
                  >
                    <a>
                      <Button
                        _focus={{
                          boxShadow: "none",
                        }}
                        variant={"link"}
                        color="green.500"
                        fontWeight={"normal"}
                        id="listingTypeBtn"
                      >
                        {listingBusinessCategory.value}
                        {/* Electronic and Electrical Equipment Manufacturing */}
                      </Button>
                    </a>
                  </Link>
                )
              )}
            </S.TypesWithListingsSection>
          </Box>
        </Flex>

        <Divider my="40px"></Divider>
        <S.CollapsibleFooterListContainer>

          <CollapsibleFooterList
            headerText="Properties by Type"
            list={listingCategories.listingPropertyTypes}
          >
            {(item) => (
              <Box>
                <Link href={item.link}>
                  <a>
                    <Text
                      as="button"
                      variant={"link"}
                      color="green.500"
                      fontWeight={"normal"}
                      textAlign="left"
                    // w="190px"
                    >
                      {item.value}
                    </Text>
                  </a>
                </Link>
              </Box>
            )}
          </CollapsibleFooterList>
          <CollapsibleFooterList
            headerText="Businesses by Type"
            list={listingCategories.businessesByType}
          >
            {(item) => (
              <Box>
                <Link href={item.link}>
                  <a>
                    <Text
                      as="button"
                      variant={"link"}
                      color="green.500"
                      fontWeight={"normal"}
                      textAlign="left"
                    // w="190px"
                    >
                      {item.value}
                    </Text>
                  </a>
                </Link>
              </Box>
            )}
          </CollapsibleFooterList>
          <CollapsibleFooterList
            headerText="Properties by City"
            list={listingCategories.propertiesByCity}
          >
            {(item) => (
              <Box>
                <Link href={item.link}>
                  <a>
                    <Text
                      as="button"
                      variant={"link"}
                      color="green.500"
                      fontWeight={"normal"}
                      textAlign="left"
                    // w="190px"
                    >
                      {item.value}
                    </Text>
                  </a>
                </Link>
              </Box>
            )}
          </CollapsibleFooterList>
          <CollapsibleFooterList
            headerText="Properties by Province"
            list={listingCategories.propertiesByProvince}
          >
            {(item) => (
              <Box>
                <Link href={item.link}>
                  <a>
                    <Text
                      as="button"
                      variant={"link"}
                      color="green.500"
                      fontWeight={"normal"}
                      textAlign="left"
                    // w="190px"
                    >
                      {item.value}
                    </Text>
                  </a>
                </Link>
              </Box>
            )}
          </CollapsibleFooterList>

          <CollapsibleFooterList
            headerText="Businesses by City"
            list={listingCategories.businessesByCity}
          >
            {(item) => (
              <Box>
                <Link href={item.link}>
                  <a>
                    <Text
                      as="button"
                      variant={"link"}
                      color="green.500"
                      fontWeight={"normal"}
                      textAlign="left"
                    // w="190px"
                    >
                      {item.value}
                    </Text>
                  </a>
                </Link>
              </Box>
            )}
          </CollapsibleFooterList>
          <CollapsibleFooterList
            headerText="Businesses by Province"
            list={listingCategories.businessesByProvince}
          >
            {(item) => (
              <Box>
                <Link href={item.link}>
                  <a>
                    <Text
                      as="button"
                      variant={"link"}
                      color="green.500"
                      fontWeight={"normal"}
                      textAlign="left"
                    // w="190px"
                    >
                      {item.value}
                    </Text>
                  </a>
                </Link>
              </Box>
            )}
          </CollapsibleFooterList>
        </S.CollapsibleFooterListContainer>
      </Box>
        }
    </>
  );
};

interface CollapsibleFooterListProps {
  list: any[];
  headerText: string;
  children: (param: any) => React.ReactNode;
}

function CollapsibleFooterList(props: CollapsibleFooterListProps) {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleCollapsed = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <Flex
      flexDir={"column"}
      justifyContent="flex-start"
      alignItems={"space-around"}
      gap="10px"
    >
      <Button
        variant="unstyled"
        _focus={{
          boxShadow: "none",
        }}
        rightIcon={isCollapsed ? <BiCaretDown /> : <BiCaretUp />}
        fontWeight="normal"
        onClick={toggleCollapsed}
        p="0"
        size="xm"
        textAlign={"left"}
      >
        {props.headerText}
      </Button>
      <VStack gap={"20px"} alignItems="flex-start">
        {!isCollapsed &&
          props.list.map((item) => {
            return props.children(item);
          })}
      </VStack>
    </Flex>
  );
}

// Landing.faq = homeFAQ;
// Landing.newLayout = true;
Landing.componentName = 'Landing'

export default Landing;
// export default withUser(Landing, { newLayout: true });

export async function getStaticProps() {

  const getNewListingsByType = async (propertyType: string) => {
    const newListings = await prisma.listing.findMany({
      where: {
        propertyTypes: {
          hasSome: propertyType
        },
        status: "Available",
        User: {
          slug: {
            not: 'Corry-S'
          }
        }
      },
      orderBy: {
        timeStamp: 'desc',
      },
      select: {
        id: true,
        subject: true,
        subType: true,
        propertyTypes: true,
        type: true,
        priceType: true,
        city: true,
        showAddress: true,
        price: true,
        unpriced: true, offMarket: true,
        imageOrder: true, imageURLs: true, imagesFresh: true,
        timeStamp: true,
        primaryImg: true,
        bedrooms: true,
        sqft: true,
        lotSize: true,
        lotSizeUnit: true,
        cap: true,
        province: true,
        priority: true,
        User: {
          select: {
            brokerage: true,
            fullName: true,
            photoURL: true,
            industryRole: true,
            pro: true,
            plan: true
          }
        }
      },
      take: 8
    });
    if (propertyType.toLocaleLowerCase() === 'sale of business') {
      return { "Businesses": newListings }
    }
    return { [propertyType]: newListings }
  };

  const newListings = await Promise.all(
    [
      getNewListingsByType('Retail'),
      getNewListingsByType('Multifamily'),
      getNewListingsByType('Sale of Business'),
      getNewListingsByType('Industrial'),
      getNewListingsByType('Office')
    ]
  );

  const newListingsObj = newListings.reduce((acc, curr) => {
    acc = { ...acc, ...curr }
    return acc
  }, {})


  return {
    props: {
      newListings: JSON.parse(JSON.stringify(newListingsObj)),
      newLayout: true
    },
    revalidate: 3600
  }

}